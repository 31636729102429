<template>
  <div>
    <div class="course">
      <div class="course__hero">
        <h1 class="course__title">{{ course.courseName }}</h1>
        <div class="container">
          <div class="course-intro__video d-flex justify-content-center mt-5 row">
            <div class="video col-12 col-md-7 p-2 p-md-0">
              <div class="video__wrapper" ref="wrapper">

                <iframe v-if="course.trailerVideoUrl" :src="`${course.trailerVideoUrl}?&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen=""
                  ref="vimeo">
                </iframe>
              </div>
            </div>
            <div class="meta p-3 col-12 col-md-5 m-2 mb-0 m-md-0 position-relative">
              <div>
                <div class="img">
                  <!-- <h3 class="meta__title">第一章 SOLIDWORKS 基礎與使用者介面</h3> -->
                  <img
                    v-if="course.coursePrevImgUrl1 !== 'NotSet'"
                    :src="course.coursePrevImgUrl1"
                    alt=""
                    width="160"
                  >
                  <img
                    class="mt-4 mb-2"
                    v-if="course.coursePrevImgUrl1 == 'NotSet'"
                    src="../assets/images/notebook.svg"
                    alt=""
                    width="250"
                  >
                  <p>{{ course.courseName }}</p>
                  <div class="d-flex flex-column mt-2">
                    <span class="text-muted mb-2">課程售價</span>
                    <strong class="text-right courses__card-price">NT$ <span>{{ course.price }}</span></strong>
                  </div>
                  <a @click.prevent="goCheckout(course.price)"
                    class="btn btn-main btn-start"
                    style="line-height: 2rem;">{{ course.price === 0 ? '加入此課程' : '購買課程'}}</a>
                </div>
              </div>
              <div class="course__channel" v-if="hasSalesChannel">
                <div class="course__channel__txt">
                  {{ course.salesChannel.name }}
                </div>
              </div>
            </div>
        </div>
        </div>

      </div>
      <!-- <ul class="nav nav-tabs nav-tabs-bordered tablist">
        <li class="nav-item">
          <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#course-overview">概覽</button>
        </li>

        <li class="nav-item">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#course-">編輯</button>
        </li>

        <li class="nav-item">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">設定</button>
        </li>

        <li class="nav-item">
          <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">更改密碼</button>
        </li>
      </ul> -->
      <div class="container">
        <div class="row">
          <!-- 左側 -->
          <div class="col-12 col-md-9 text-start">
            <div class="course__about py-3 py-md-5 mt-3">
              <h3 class="course__subtitle">關於課程</h3>
              <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                  <img src="../assets/images/users-100.png" alt="">
                  <div class="d-flex flex-column">
                    <span class="mb-2 text-muted">授課教師</span>
                    <span>{{ course.authorName }}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                  <img src="../assets/images/clock.svg" alt="">
                  <div class="d-flex flex-column">
                    <span class="mb-2 text-muted">單元數</span>
                    <span v-if="course">{{ totalChapter }} 章節 {{ totalSection }} 單元 (約 {{course.duration.hour}} 小時 {{course.duration.min}} 分)</span>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center mb-3">
                  <img src="../assets/images/skills.png" alt="" class="me-1">
                  <div class="d-flex flex-column">
                    <span class="mb-2 text-muted">課程標籤</span>
                    <div>
                      <span v-for="tag in course.courseHashtag" :key="tag" class="badge rounded-pill course__tag">{{ tag }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="course__about py-3 py-md-3 mt-3" v-if="course&& course.files.length > 0">
              <h3 class="course__subtitle">課程範例檔案</h3>
              <div class="row">
                <div class="col-12 col-md-6 d-flex align-items-center mb-3" v-for="(item, i) in course.files" :key="i">
                  <img src="../assets/images/file-upload.svg" alt="">
                  <div class="d-flex flex-column">
                    <p class="mb-1">{{ item.fileName }}</p>
                    <a class="mb-2 text-muted" :href="item.link" target="_blank">壓縮檔案</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="course__desc py-3 py-md-5">
              <h3 class="course__subtitle">課程詳情</h3>
              <div class="course__introtxt" v-html="decodeEntity(course.courseDescription).split('\n').join('<br>')"></div>
            </div>
            <div class="course__accor py-3 py-md-5">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item" v-for="(item, i) in chapters" :key="i">
                  <h2 class="accordion-header" :id="`L${i}`">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${i}`" aria-expanded="false" :aria-controls="`collapse${i}`">
                      {{ item.title }}
                    </button>
                  </h2>
                  <!-- <template v-if="i==0"> -->
                    <div :id="`collapse${i}`" class="accordion-collapse collapse" :aria-labelledby="`heading${i}`" >
                    <div class="accordion-body p-2">
                      <ul>
                        <li class="course__accor__list d-flex justify-content-between"
                          v-for="(sub, j) in item.chaperItem" :key="j"
                          >
                          <!-- <i v-if="sub.lock" class="fas fa-lock me-2 d-inline-block"></i>
                          <i v-if="!sub.lock" class="fas fa-play me-2 d-inline-block"></i> -->
                          <span class="title" style="flex: 1 1 0%;" v-if="sub.lock">{{ sub.title }}</span>
                          <span class="title fw-bold" style="flex: 1 1 0%;text-decoration: underline;"
                            v-if="!sub.lock" data-bs-target="#freeVideoModal" data-bs-toggle="modal" @click="videoUrl = sub.videoUrl;freeVideoTitle = sub.title">{{ sub.title }}</span>
                          <span class="time">{{ formatTime(sub.duration.min) }} : {{ formatTime(sub.duration.sec) }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- </template> -->
                  <!-- <template v-if="i!==0">
                    <div :id="`collapse${item.id}`" class="accordion-collapse collapse" :aria-labelledby="`heading${item.id}`">
                    <div class="accordion-body p-2">
                      <ul>
                        <li class="course__accor__list d-flex justify-content-between"
                          v-for="sub in item.subChapter" :key="sub.id"
                          >
                          <i class="far fa-circle me-2 d-inline-block"></i>
                          <span class="title" style="flex: 1 1 0%;">{{ sub.name }}</span>
                          <span class="time">{{ sub.duration }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  </template> -->
                </div>
              </div>
            </div>

            <div class="course__review py-3 py-md-5">
              <h3 class="course__subtitle">課程評論</h3>
              <template v-if="commentLoading== true">
                <div>
                  <i class="fas fa-spinner course__spinner"></i>
                </div>
              </template>
              <template v-if="commentLoading== false">
                <div class="course__review__card">
                  <div class="card" style="border-radius: 0rem;">

                    <div v-for="(item, i) in comments" :key="item.id">
                      <div class="card-body p-4 text-start d-flex justify-content-start align-items-sm-center  flex-column flex-sm-row">
                        <!-- sm and larger -->
                        <div class="card-photo ms-3 me-5 d-none d-sm-block">
                          <div class="card-imgfr mb-2">
                            <img :src="item.avatar == 'NotSet' ? '/static/images/user.png': `${item.avatar}`" alt="" class="rounded-circle card-header p-0">
                          </div>
                          <p class="course__review__user mb-2">{{ item.userName }}</p>
                          <span>
                            <time class="text-muted">{{ item.updateTime.split(' ')[0] }}</time>
                          </span>
                        </div>
                        <!-- sm and smaller -->
                        <div class="card-photo me-5 d-block d-sm-none">
                          <div class="d-flex">
                            <div class="card-imgfr mb-2 me-4">
                              <img :src="item.avatar == 'NotSet' ? '/static/images/user.png': `${item.avatar}`" alt="" class="rounded-circle card-header p-0">
                            </div>
                            <div class="">
                              <p class="course__review__user mb-2">{{ item.userName }}</p>
                              <span>
                                <time class="text-muted">{{ item.updateTime.split(' ')[0] }}</time>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="card-right">
                          <div class="courses__rating mb-3">
                            <star-rating
                              :rating="item.starRating"
                              :show-rating="false"
                              :rounded-corners="true"
                              :star-size="20"
                              :read-only="true"
                              :increment="0.5"
                              :border-width="0" />
                          </div>
                          <strong class="fw-bold mb-3 d-block">{{ item.title }}</strong>
                          <p style="line-height: 1.4rem;">{{ item.comment}}</p>

                        </div>
                      </div>
                      <hr class="my-0 mx-auto course__review__hr" :class="{'d-none': i === comments.length - 1}">
                    </div>

                  </div>
                </div>
                <div class="course__review__pages mt-3 d-flex justify-content-center">
                  <i class="fas fa-chevron-left me-3 course__review__page--switch"
                    v-if="nowPage > 1" @click.prevent="switchPage(-1)"></i>
                  <i class="fas fa-chevron-right ms-3 course__review__page--switch"
                    v-if="nowPage < totalPages" @click.prevent="switchPage(1)"></i>
                </div>
              </template>
            </div>
          </div>
          <!-- 右側 -->
          <div class="col-12 col-md-3">

          </div>

        </div>
      </div>
      <section class="container-fluid mt-3">
        <div class="container position-relative">
          <h2 class="course__related mb-3">相關課程</h2>
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="item in recommendData" :key="item.id">
              <CourseCard :course="item"></CourseCard>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev-1 swiper-btn d-flex justify-content-center align-items-center" slot="button-prev"> &lt; </div>
          <div class="swiper-button-next-1 swiper-btn d-flex justify-content-center align-items-center" slot="button-next"> &gt; </div>
        </div>
      </section>

      <div class="modal fade" id="freeVideoModal" tabindex="-1" aria-labelledby="freeVideoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="pauseVideo"></button>
            </div>
            <div class="modal-body">
              <h3 class="mb-3">{{ freeVideoTitle }}</h3>
              <div class="video__wrapper" ref="wrapper">
                <vue-vimeo-player ref="player"
                  :video-id="videoUrl.split('/')[4].split('?')[0]"
                  :video-url="`${videoUrl}?&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  :options="{ responsive: true }"
                  ></vue-vimeo-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="title" />
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { mapActions, mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import CourseCard from '../components/CourseCard';
import 'swiper/css/swiper.css';

const Swal = require('sweetalert2');

export default {
  name: 'Course',
  components: {
    Swiper,
    SwiperSlide,
    CourseCard,
    StarRating,
    vueVimeoPlayer,
  },
  data() {
    return {
      data: [],
      testTxt: '若要加購書本，可去蝦皮賣場(https://shopee.tw/yr8zg9tbdz)進行加購。',
      course: '',
      chapters: [],
      totalChapter: 0,
      totalSection: 0,
      recommendData: [],
      id: '',
      swiperOption: {
        spaceBetween: 30,
        slidesPerView: 4,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next-1',
          prevEl: '.swiper-button-prev-1',
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          576: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          876: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
      commentLoading: false,
      comments: [],
      nowPage: 1,
      totalPages: 1,
      height: 433,
      videoWidth: '',
      videoHeight: '',
      title: '元學堂',
      videoUrl: 'https://player.vimeo.com/video/735690297?h=2dfd01d146',
      freeVideoTitle: '',
      hasSalesChannel: false,
    };
  },
  metaInfo() {
    return {
      title: this.course.courseName,
      meta: [
        { name: 'description', content: this.course.courseDescription },
        {
          property: 'og:title',
          content: this.course.courseName,
        },
        {
          property: 'og:description',
          content: this.course.courseDescription,
        },
      ]
    }
  },
  methods: {
    getData() {
      const query = {
        CourseName: 'SOLIDWORKS',
        CourseCategoryId: 1,
        OrderBy: 'lastest',
        Ordering: 'ASC',
        page: 1,
      };
      this.$http.get(`${this.$API_PATH}/Course/Selected?Take=6`, query, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.recommendData = res.data.data.selectedCourseList.slice(0, 5);
      }).catch((err) => {
        this.$router.push('/error');
        // this.$log.debug(err.response);
      });
    },
    getComments() {
      const vm = this;
      this.commentLoading = true;
      this.$http.get(`${this.$API_PATH}/Course/Comment?CourseId=${this.id}&Page=${this.nowPage}`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.commentLoading = false;
        this.comments = res.data.data.courseCommentList;
        this.totalPages = Math.ceil(res.data.data.total / 6);
        this.$log.debug(this.totalPages);
      }).catch((err) => {
        this.commentLoading = false;
        // this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    switchPage(num) {
      this.nowPage += num;
      if (this.nowPage < 1) {
        this.nowPage = 1;
      }
      if (this.nowPage > this.totalPages) {
        this.nowPage = this.totalPages;
      }
      this.getComments();
    },
    getCourse() {
      let api;
      if(this.$route.query.salesChannel !== undefined) {
        api = `${this.$API_PATH}/Course?salesChannel=${this.$route.query.salesChannel}&CourseId=${this.id}`;
      } else {
        api = `${this.$API_PATH}/Course?CourseId=${this.id}`;
      }
      this.$http.get(api, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.course = res.data.data;
        if (this.course.purchased) {
          this.$router.replace(`/course_main/${this.course.courseId}`);
        }
        // console.log(this.course);
        this.chapters = Array.from(res.data.data.chapter);
        let chapter = 0;
        let section = 0;
        this.chapters.forEach((item) => {
          chapter++;
          item.chaperItem.forEach((el) => {
            section++;
          });
        });
        this.totalChapter = chapter;
        this.totalSection = section;
        document.documentElement.style.setProperty('--c-main', `url(${this.course.coursePrevImgUrl1})`);
        this.title = `${this.course.courseName} - 元學堂`;
        const today = new Date();
        if(res.data.data.salesChannel !== null 
          && today.getTime() > new Date(res.data.data.salesChannel.startTime).getTime()
          && today.getTime() < new Date(res.data.data.salesChannel.endTime).getTime()) {
          this.hasSalesChannel = true;
        } else {
          this.hasSalesChannel = false;
        }
      }).catch((err) => {
        // this.$router.push('/error');
        if(err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
          this.$router.push(`/`);
        }
        this.$log.debug(err.response);
      });
    },
    goCheckout(price) {
      const vm = this;
      if (this.userAuth.length !== 0 && this.userState.length !== 0) {
        if(price == 0) {
          this.$http.post(`${this.$API_PATH}/Checkout/Free`, {courseId: this.id}, {
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              Authorization: `bearer ${this.userAuth}`,
            },
          }).then((res) => {
            Swal.fire(
              '',
              `${res.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push(`/course_main/${vm.id}`);
              }
            });
          }).catch((err) => {
            if(err.response) {
              Swal.fire(
                '',
                `${err.response.data.message}`,
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                }
              });
            } else {
              Swal.fire(
                '',
                `錯誤，請稍後再試`,
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  vm.$router.push('/');
                }
              });
            }
            
          });
        } else {
          if(this.hasSalesChannel) {
            this.$router.push(`/checkout/${this.id}?salesChannel=${this.$route.query.salesChannel}`);
          } else {
             this.$router.push(`/checkout/${this.id}`);
          }
        }
      } else {
        Swal.fire(
          '',
          '請先登入',
          'info',
        );
      }
    },
    getState(data) {
      // this.$log.debug(data);
    },
    formatTime(num) {
      return num < 10 ? `0${num}` : num;
    },
    pauseVideo() {
      this.$refs.player.pause();
    },
    decodeEntity(inputStr) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = inputStr;
      return textarea.value;
    },
    ...mapActions(['updateLoading', 'getUserAuth', 'getUserFromLocal', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  computed: {
    ...mapGetters(['userAuth', 'userState']),
  },
  mounted() {
    if (window.innerWidth >= 1400) {
      this.videoWidth = window.innerWidth * 0.5833 - (window.innerWidth - 1320 - 24);
      this.videoHeight = this.videoWidth * 0.5625;
    } else if (window.innerWidth >= 1200) {
      this.videoWidth = window.innerWidth * 0.5833 - (window.innerWidth - 1120 - 24);
      this.videoHeight = this.videoWidth * 0.5625;
    } else if (window.innerWidth >= 992) {
      this.videoWidth = window.innerWidth * 0.5833 - (window.innerWidth - 960 - 24);
      this.videoHeight = this.videoWidth * 0.5625;
    } else if (window.innerWidth >= 768) {
      this.videoWidth = window.innerWidth * 0.5833 - (window.innerWidth - 720 - 24);
      this.videoHeight = this.videoWidth * 0.5625;
    } else {
      this.videoWidth = window.innerWidth * 1 - 20;
      this.videoHeight = this.videoWidth * 0.5625;
    }
    // console.log(this.videoWidth);
  },
  created() {
    this.id = this.$route.params.id;
    this.getUserFromLocal();
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    this.getCourse();
    this.getData();
    this.getComments();
  },
};
</script>

<style lang="scss" scoped>
.video__wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

.video__wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.shopee__link {
  text-decoration: none;
  color: black;
}

</style>
